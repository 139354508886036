import React, { createElement } from "react";
import {
  Admin,
  Resource,
  Layout,
  MenuItemLink,
  Responsive,
  getResources,
} from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import simpleRestProvider from "ra-data-simple-rest";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom";

import LocalBarIcon from "@material-ui/icons/LocalBar";
import StarsIcon from "@material-ui/icons/Stars";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import OpacityIcon from "@material-ui/icons/Opacity";
import WidgetsIcon from "@material-ui/icons/Widgets";
import SyncIcon from "@material-ui/icons/Sync";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import ReceiptIcon from "@material-ui/icons/Receipt";

import { CustomerList, CustomerEdit } from "./customer";
import { ProductList, ProductEdit } from "./product";
import { SalesMonthList } from "./salesmonth";
import { VesselList, VesselCreate, VesselEdit } from "./vessels";
import { TransferList } from "./transfers";
import { SalesRepList, SalesRepCreate, SalesRepEdit } from "./salesreps";
import { InvoiceList, InvoiceEdit } from "./invoices";

import { QBOPage } from "../pages/qbo";
import { HelcimPage } from "../pages/helcim";
import { LoginPage, LoginCallbackPage, AuthorizePage } from "../pages/login";
import LogoutPage from "../pages/logout";
import { StockistsPage } from "../pages/stockists";

import authProvider from "./authProvider";
import { fetchJson } from "./httpClient";

const history = createBrowserHistory();
const dataProvider = simpleRestProvider("/api/v1", fetchJson);

const customMenu = ({ resources, onMenuClick, logout }) => (
  <div>
    {resources.map((resource) => (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={
          (resource.options && resource.options.label) || resource.name
        }
        leftIcon={createElement(resource.icon)}
        onClick={onMenuClick}
      />
    ))}
    <MenuItemLink
      to="/qbo"
      primaryText="QuickBooks"
      leftIcon={<SyncIcon />}
      onClick={onMenuClick}
    />
    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
);
const mapStateToProps = (state) => ({
  resources: getResources(state),
});

const customLayout = (props) => (
  <Layout {...props} menu={withRouter(connect(mapStateToProps)(customMenu))} />
);

const routes = [
  <Route exact path="/qbo" component={QBOPage} />,
  <Route exact path="/helcim" component={HelcimPage} />,
  <Route exact path="/callback" component={LoginCallbackPage} />,
  <Route exact path="/authorize" component={AuthorizePage} />,
  <Route exact path="/logout" component={LogoutPage} />,
  <Route exact path="/stockists" component={StockistsPage} />,
];

const ReactAdminApp = () => (
  <Admin
    appLayout={customLayout}
    dataProvider={dataProvider}
    history={history}
    customRoutes={routes}
    loginPage={LoginPage}
    authProvider={authProvider}
  >
    <Resource
      name="products"
      icon={StarsIcon}
      list={ProductList}
      edit={ProductEdit}
      options={{ label: "Products" }}
    />
    <Resource
      name="customers"
      icon={LocalBarIcon}
      list={CustomerList}
      edit={CustomerEdit}
      options={{ label: "Customers" }}
    />
    <Resource
      name="salesrep"
      icon={DirectionsRunIcon}
      list={SalesRepList}
      create={SalesRepCreate}
      edit={SalesRepEdit}
      options={{ label: "Sales Reps" }}
    />
    <Resource
      name="invoices"
      icon={ReceiptIcon}
      list={InvoiceList}
      edit={InvoiceEdit}
      options={{ label: "Invoices" }}
    />
    <Resource
      name="salesmonth"
      icon={CreditCardIcon}
      list={SalesMonthList}
      options={{ label: "Sales Months" }}
    />
    <Resource
      name="vessels"
      icon={OpacityIcon}
      list={VesselList}
      create={VesselCreate}
      edit={VesselEdit}
      options={{ label: "Bulk Vessels" }}
    />
    <Resource
      name="transfers"
      icon={WidgetsIcon}
      list={TransferList}
      options={{ label: "Bulk Transfers" }}
    />
  </Admin>
);

export default ReactAdminApp;
