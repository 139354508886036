import React, { Component } from "react";
import { Button } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchRegular } from "../react-admin/httpClient";

export class HelcimPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      output: "",
    };
    this.syncAll = this.syncAll.bind(this);
  }

  async syncAll() {
    this.setState({ syncing: true });
    fetchRegular("/api/v1/helcim/sync")
      .then(async (resp) => {
        let reader = (await resp.body).getReader();
        const utf8Decoder = new TextDecoder("utf-8");
        let done = false;
        do {
          const r = await reader.read();
          const value = r.value ? utf8Decoder.decode(r.value) : "";
          done = r.done;

          this.setState({
            output: this.state.output + value,
          });
        } while (!done);
        this.setState({
          syncing: false,
        });
      })
      .catch((err) => {
        this.setState({
          syncing: false,
          error: err,
        });
      });
  }

  render() {
    const button = (
      <div>
        <div>
          <span>
            <Button
              variant="contained"
              size="large"
              label="Sync All"
              onClick={this.syncAll}
              disabled={this.state.syncing}
            >
              {this.state.syncing ? <CircularProgress size={12} /> : null}
            </Button>
          </span>
        </div>
        <div>
          <p style={{ whiteSpace: "pre" }}>{this.state.output}</p>
        </div>
      </div>
    );

    return <div>{button}</div>;
  }
}
